import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect } from 'react'

function Atendimento() {
  const IframeAtendimento = () => (
    <>
      <iframe
        title="Atendimento"
        className="w-full block overflow-hidden border-0 min-h-screen iframe-atendimento"
        src="https://decathlon.custhelp.com/app/home"
        height="173.611vw"
      />
    </>
  )

  function ChatbotAtendimento() {
    const el = document.createElement('script')

    el.id = 'chatbot_script'
    el.type = 'text/javascript'
    el.src = 'https://bot.bluelab.com.br/chat/v2/react'
    el.setAttribute('company', 'decathlon')
    el.setAttribute('alias', 'decathlon')
    document.body.appendChild(el)
  }

  useEffect(() => {
    ChatbotAtendimento()
  }, [])

  return (
    <>
      <GatsbySeo language="pt-br" title="Atendimento | Decathlon" />
      <IframeAtendimento />
    </>
  )
}

export default Atendimento
